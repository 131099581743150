<template>
    <CompContent title="单个指标数据统计">
        <div class="content" v-if="detail">
            <div class="count-box">
                {{ detail.appName + "总数：" }}
                <p class="value" @click="route.jump(`/residentdatabasedatatable?id=${appId}&title=${detail.appName}&d=false&orgCode=${orgCode}&s=report&type=street`)">{{ detail.count }}</p>
            </div>
            <div class="row—box">
                <div class="item-box">
                    <ElemChart class="chart-box" :option="chart_option"></ElemChart>
                </div>
                <div class="item-box">
                    <ElemTable isIndex :columns="columns" :dataList="detail.communityUserSumList" height="100%"></ElemTable>
                </div>
            </div>
        </div>
    </CompContent>
</template>

<script>
import ElemTable from "../../communitymobilization/components/ElemTable.vue"
import ElemChart from "../../managsystem/childrens/ElemChart.vue"
import CompContent from "../../customform/components/CompContent.vue"

import Street from "../../managsystem/utils/street"
import Request from "../../jointly/utils/request"
import Route from "../../communitymobilization/entity/Route"

export default {
    components: { ElemTable, ElemChart, CompContent },

    data() {
        return {
            route: Route,
            detail: null,
            chart_option: null,
            columns: null,
            appId: this.$core.getUrlParam("appId"),
            orgCode: null,
        }
    },

    async created() {
        const info = await Street.getInfo()
        // 街道编码
        this.orgCode = info.orgCode
        // 获取数据
        this.getData()
    },

    methods: {
        getData() {
            Request.get("/gateway/sy-user/sy-user/api/syuser/pc/streetUser/countByAppId?appId=" + this.appId).then(res => {
                this.detail = res
                // 表格配置
                this.columns = [
                    {
                        title: "社区",
                        key: "orgName",
                    },
                    {
                        title: res.appName,
                        sort: "desc",
                        key: "count",
                        render: (h, params) => {
                            return h(
                                "p",
                                {
                                    style: {
                                        color: "#3398DB",
                                        "text-decoration": "underline",
                                    },
                                },
                                params.row.count
                            )
                        },
                        click: params => {
                            Route.jump(`/residentdatabasedatatable?id=${this.appId}&title=${res.appName}&d=false&orgCode=${params.row.orgCode}&s=report`)
                        },
                    },
                ]
                // 获取图表数据
                this.chart_option = this.getChartOption(res.communityUserSumList)
            })
        },

        getChartOption(d) {
            return {
                tooltip: {
                    trigger: "axis",
                },
                color: ["#3398DB"],
                yAxis: {
                    type: "category",
                    data: d.map(v => v.orgName),
                },
                xAxis: {
                    type: "value",
                    scale: true,
                },
                series: [
                    {
                        name: this.title,
                        type: "bar",
                        data: d.map(v => v.count || 0),
                    },
                ],
            }
        },
    },
}
</script>

<style lang="less" scoped>
.content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 10px;

    .count-box {
        font-size: 20px;
        font-weight: bold;
        margin-top: 20px;
        display: flex;
        align-items: center;
        line-height: 1;

        .value {
            cursor: pointer;
            font-size: 28px;
            text-decoration: underline;
            color: #3398db;
        }
    }

    .row—box {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        overflow: hidden;
        padding: 20px 0;

        .item-box {
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);

            &:nth-child(1) {
                width: 59%;
                border: 1px solid #f3f3f3;
                border-radius: 6px;
            }

            &:nth-child(2) {
                width: 39%;
            }
        }
    }
}
</style>
